import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar/TopBar";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import "./GetStarted.css";
import { useFontDesk } from "../../context/frontDeskContext";
import { AiOutlineWarning } from "react-icons/ai";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { Strings } from "../../utils/strings";
import { getMessagingToken, onMessageListener } from "../../firebase";

import proIcon from "../../assets/pro_icon.jpg";
import calendarIcon from "../../assets/calendar_icon.jpg";
import orderIcon from "../../assets/order_icon.png";
import {
  requestNotificationPermission,
  sendNotification,
} from "../../utils/utils";
import { usePageVisibility } from "react-page-visibility";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { AdminUser, CoachUser } from "../../utils/constants";

export const GetStarted = () => {
  const {
    iosInstructions,
    setIosInstructions,
    notificationPermission,
    setNotificationPermission,
    getFCMToken,
    setFCMToken,
  } = useFontDesk();

  const isVisible = usePageVisibility();
  useEffect(() => {
    if (!isVisible) return;
  }, [isVisible]);

  const subscribeToFCMTopic = async (token: string | null) => {
    if (token && getFCMToken() !== token) {
      try {
        const response = await FrontDeskApi.subscribeToTopic(token ?? "");
        if (response.isSuccess) {
          setFCMToken(token);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (notificationPermission === "granted") {
      try {
        getMessagingToken().then((token) => {
          subscribeToFCMTopic(token);
        });
        onMessageListener()
          .then((payload) => {
            sendNotification(payload.notification.title, {
              body: payload.notification.body,
            });
          })
          .catch((err) => console.log("failed: ", err));
      } catch (e) {
        console.log(e);
      }
    } else if (notificationPermission === "denied") {
      alert(Strings.NOTIFICATION_PERMISSION_DENIED);
    } else {
      setModalData({
        title: Strings.NOTIFICATION_PERMISSION,
        children: Strings.NOTIFICATION_PERMISSION_REQUIRED,
        btnText: Strings.ACCEPT,
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: handleClose,
        onAccept: () =>
          requestNotificationPermission(handleClose, setNotificationPermission),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPermission]);

  useEffect(() => {
    if (iosInstructions) {
      setModalData({
        title: Strings.ENABLE_NOTIFICATION,
        children: Strings.IOS_PERMISSION,
        btnText: "Close",
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: () => {
          setIosInstructions(false);
        },
        onAccept: undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iosInstructions]);

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    onAccept?: () => void;
    headerIcon?: React.ReactNode;
  }>();

  const {
    onLogOut,
    onBookPro,
    onCoachAvailability,
    onOrders,
    staffModel,
    onMySchedule,
  } = useFontDesk();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
  };

  return (
    <>
      <div className="outer-container">
        <TopBar
          onLogout={() => {
            setModalData({
              title: Strings.LOGOUT,
              children: Strings.LOGOUT_WARNING,
              btnText: Strings.LOGOUT,
              btnVariant: "outline-danger",
              headerIcon: (
                <AiOutlineWarning
                  style={{
                    marginRight: "0.5em",
                    color: "red",
                    alignSelf: "center",
                  }}
                />
              ),
              onClose: () => {
                handleClose(true);
                onLogOut();
              },
            });
            setShow(true);
          }}
          // onBookPro={onCoachAvailability}
        />
        {!staffModel ? (
          <LoadingIndicator loading={true} />
        ) : (
          <div className="getstarted-container">
            <div className="getstarted-innerContainer" onClick={onBookPro}>
              <img
                src={proIcon}
                className="getstarted-icon"
                alt="Description"
              />
              <p className="getstarted-title">Book A Pro</p>
            </div>
            {staffModel?.role === CoachUser.value && (
              <>
                <div
                  className="getstarted-innerContainer"
                  onClick={onCoachAvailability}>
                  <img
                    src={calendarIcon}
                    className="getstarted-icon"
                    alt="Description"
                  />
                  <p className="getstarted-title">Coach Availability</p>
                </div>
                <div
                  className="getstarted-innerContainer"
                  onClick={onMySchedule}>
                  <img
                    src={calendarIcon}
                    className="getstarted-icon"
                    alt="Description"
                  />
                  <p className="getstarted-title">My Schedule</p>
                </div>
              </>
            )}
            {staffModel?.role === AdminUser.value && (
              <div className="getstarted-innerContainer" onClick={onOrders}>
                <img
                  src={orderIcon}
                  className="getstarted-icon"
                  alt="Description"
                />
                <p className="getstarted-title">Orders</p>
              </div>
            )}
          </div>
        )}
        <StaticBackdropModal
          show={show}
          handleOpen={modalData?.onAccept ?? modalData?.onClose ?? handleClose}
          handleClose={handleClose}
          title={modalData?.title}
          children={modalData?.children}
          btnText={modalData?.btnText}
          headerIcon={modalData?.headerIcon}
        />
      </div>
    </>
  );
};
